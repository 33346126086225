<template>
  <div>
    <div class="d-flex justify-content-between align-items-center">
      <label class="font-semi-bold m-0 d-block">{{ title }}</label>
      <LinkPlusComponent onlyIcon @onClick="onAddCategories" />
    </div>

    <el-select
      filterable
      v-model="selectCategories"
      placeholder="Seleccionar"
      @change="onChangeSelect"
      multiple
    >
      <el-option
        v-for="(option, index) in categories"
        :key="index"
        :label="option.title"
        :value="option.id"
      ></el-option>
    </el-select>

    <el-dialog
      :visible.sync="dialogCategoriesAdd"
      :before-close="handleCloseCategoriesAdd"
      append-to-body
    >
      <CategoryAddComponent
        :showBackIcon="false"
        v-if="dialogCategoriesAdd"
        @onSuccess="onSuccessCategoriesAdd"
      />
    </el-dialog>
  </div>
</template>

<script>
import catalogueService from "../catalogue/services/catalogueService";
import CategoryAddComponent from "../catalogue/components/categories/Add";
import LinkPlusComponent from "./LinkPlus";

export default {
  name: "SelectCategories",
  data() {
    return {
      selectCategories: this.setSelectCategories,
      categories: [],
      dialogCategoriesAdd: false
    };
  },
  computed: {},
  methods: {
    onAddCategories() {
      this.dialogCategoriesAdd = true;
    },
    onChangeSelect() {
      this.$emit("onChangeSelect", {
        selectCategories: this.selectCategories
      });
    },
    onSuccessCategoriesAdd({ category }) {
      this.categories = [category, ...this.categories];
    },
    handleCloseCategoriesAdd() {
      this.dialogCategoriesAdd = false;
    },
    async getCategories() {
      try {
        let response = await catalogueService.getCategories();
        if (response.data.categories) {
          this.categories = response.data.categories;
          this.onChangeSelect();
        }
      } catch (error) {
        return false;
      }
    }
  },
  components: {
    CategoryAddComponent,
    LinkPlusComponent
  },
  props: {
    title: {
      default: "Categorias"
    },
    placeholder: {
      default: "Seleccionar"
    },
    setSelectCategories: {
      type: Array,
      default: () => []
    },
    disabled: {
      default: false
    }
  },
  mounted() {
    this.getCategories();
  }
};
</script>

<style lang="sass" scoped></style>
