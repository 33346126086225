import Api from "@/config/Api";

export default {
  addProducts(data) {
    return Api().post("/catalog/products", data);
  },

  getProducts(options = {}) {
    return Api().get("/catalog/products", options);
  },

  getProductId(productId) {
    return Api().get(`/catalog/products/${productId}`);
  },

  updateProductId(product) {
    return Api().put(`/catalog/products/${product.id}`, product);
  },

  deleteProductId(productId) {
    return Api().delete(`/catalog/products/${productId}`, {});
  },

  getDownloadReportInvoicesExcel(attribute) {
    return Api().get("/catalog/products/download/excel", attribute);
  },

  downloadPdfProduct(attribute) {
    return Api().get("/catalog/products/download/pdf", attribute);
  },

  deleteProductsIds(productIds) {
    return Api().post("/catalog/products/destroy/several", productIds);
  },

  addCategories(data) {
    return Api().post("/catalog/categories", data);
  },

  getCategories(options = {}) {
    return Api().get("/catalog/categories", options);
  },

  getCategoryId(categoryId) {
    return Api().get(`/catalog/categories/${categoryId}`);
  },

  updateCategoryId(category) {
    return Api().put(`/catalog/categories/${category.id}`, category);
  },

  deleteCategoryId(categoryId) {
    return Api().delete(`/catalog/categories/${categoryId}`, {});
  },
  addAttributes(data) {
    return Api().post("/catalog/attributes", data);
  },

  getAttributes() {
    return Api().get("/catalog/attributes", {});
  },

  getAttributeId(attributeId) {
    return Api().get(`/catalog/attributes/${attributeId}`);
  },

  updateAttributeId(attribute) {
    return Api().put(`/catalog/attributes/${attribute.id}`, attribute);
  },

  deleteAttributeId(attributeId) {
    return Api().delete(`/catalog/attributes/${attributeId}`, {});
  },

  getEraserPublic(eraserPublic) {
    return Api().put(`/catalog/integration/${eraserPublic.id}`, eraserPublic);
  },

  moveProduct(data) {
    return Api().post("/catalog/products/move", data);
  },

  addInputProduct(data) {
    return Api().post("/catalog/products/input", data);
  },

  addOutputProduct(data) {
    return Api().post("/catalog/products/output", data);
  }
};

export const productTypes = [
  {
    value: "simple",
    label: "Producto simple"
  },
  {
    value: "variable",
    label: "Producto con variables"
  }
];

export const productTypesCrear = [
  {
    value: "simple",
    label: "Producto simple"
  }
];
