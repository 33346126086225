<template>
  <el-form-item :prop="inputProp">
    <slot name="label"></slot>
    <el-input
      v-model="input"
      :type="type"
      :rows="rows"
      :resize="resize"
      :maxlength="maxlength"
      :show-word-limit="showWordLimit"
      @input="onInputChange"
      :disabled="isDisabled"
    ></el-input>
  </el-form-item>
</template>

<script>
export default {
  name: "InputTextComponent",
  data() {
    return {
      input: this.value
    };
  },
  watch: {
    value(value) {
      this.input = value;
    }
  },
  methods: {
    onInputChange() {
      this.$emit("onInputChange", this.input);
    }
  },
  props: {
    maxlength: {
      default: 300
    },
    resize: {
      default: "none"
    },
    type: {
      default: "textarea"
    },
    rows: {
      default: "8"
    },
    value: {
      default: null
    },
    inputProp: {
      default: null
    },
    showWordLimit: {
      default: true
    },
    isDisabled: {
      default: false
    }
  }
};
</script>

<style lang="scss" scoped></style>
