<template>
  <div class="">
    <el-form :model="form" :rules="rules" ref="form">
      <TopBarTitleComponent title="Categorías" :showBackIcon="showBackIcon">
        <template slot="right-area">
          <div class="d-flex justify-content-end align-items-center">
            <ButtonPrimaryComponent
              title="Guardar"
              @onSaveClick="handleSaveClick"
            />
          </div>
        </template>
      </TopBarTitleComponent>

      <b-container fluid class="pb-4 px-lg-4">
        <b-row>
          <b-col>
            <div class="card box-shadow">
              <div class="card-body">
                <b-row>
                  <b-col xs="12" sm="12" md="12">
                    <el-form-item prop="title">
                      <slot name="label">
                        <label class="font-semi-bold m-0"
                          >Nombre Categoría</label
                        >
                      </slot>
                      <el-input type="text" v-model="form.title"></el-input>
                    </el-form-item>
                    <el-form-item prop="description">
                      <slot name="label">
                        <label class="font-semi-bold m-0"
                          >Descripción de la categoría</label
                        >
                      </slot>
                      <el-input
                        type="textarea"
                        rows="8"
                        resize="none"
                        v-model="form.description"
                      ></el-input>
                    </el-form-item>
                  </b-col>
                </b-row>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </el-form>
  </div>
</template>

<script>
import TopBarTitleComponent from "../../../components/TopBarTitle";
import ButtonPrimaryComponent from "../../../components/ButtonPrimary";
import Validate from "@/assets/validate";

import catalogueService from "../../services/catalogueService";

export default {
  name: "CategoryAddComponent",
  data: () => ({
    apiResponse: {
      message: null
    },
    form: {
      title: "",
      description: ""
    },
    rules: {
      title: [Validate.rules.required()]
      // description: [Validate.rules.required()]
    }
  }),
  computed: {},
  methods: {
    onChangeInput(data) {
      this.form = { ...this.form, ...data };
    },
    handleSaveClick() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          this.$store.dispatch("toggleRequestLoading");
          try {
            let response = await catalogueService
              .addCategories(this.form)
              .finally(() => {
                this.$store.dispatch("toggleRequestLoading");
              });
            const { success, message, category } = response.data;
            if (success) {
              this.$refs.form.resetFields();
              this.$notifications.success({
                message
              });
              this.$emit("onSuccess", { category });
            }
          } catch (error) {
            return false;
          }
        }
      });
    }
  },
  components: {
    TopBarTitleComponent,
    ButtonPrimaryComponent
  },
  props: {
    showBackIcon: {
      default: true,
      type: Boolean
    }
  }
};
</script>

<style></style>
