<template>
  <div class="d-flex align-items-center">
    <SwitchComponent :setValue.sync="setValue" @onChange="onChange" />
    <span class="ml-4 text-color-primary font-bold">
      Seguimiento de existencia
    </span>
  </div>
</template>

<script>
import SwitchComponent from "../../../components/Switch";

export default {
  name: "SwitchTracking",
  methods: {
    onChange({ value }) {
      this.$emit("onTracking", { tracking: value });
    }
  },
  components: {
    SwitchComponent
  },
  props: {
    setValue: {
      default: false
    }
  }
};
</script>

<style></style>
