<template>
  <el-input-number
    :controls="false"
    :placeholder="placeholder"
    v-model="getAmount"
    :precision="2"
    :step="0.1"
    @input="onChangeInput"
  ></el-input-number>
</template>

<script>
export default {
  name: "InputAmount",
  data() {
    return {
      getAmount: this.amount
    };
  },
  watch: {
    amount(v) {
      this.getAmount = v;
    }
  },
  methods: {
    onChangeInput() {
      this.$emit("onChange", {
        amount: this.getAmount
      });
    }
  },
  props: {
    amount: {
      default: 0
    },
    placeholder: {
      default: "0.00"
    }
  }
};
</script>

<style lang="scss" scoped></style>
