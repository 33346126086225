<template>
  <el-select v-model="taxesId" placeholder="Seleccionar" @change="onSelectTax">
    <el-option
      v-for="({ id, title }, index) in taxes"
      :key="index"
      :label="title"
      :value="id"
    ></el-option>
  </el-select>
</template>

<script>
import SriServices from "@/services/SriServices";

export default {
  name: "SelectTaxes",
  data() {
    return {
      taxes: [],
      taxesId: this.setTaxesId
    };
  },
  watch: {
    setTaxesId(v) {
      this.taxesId = v;
    }
  },
  methods: {
    onSelectTax() {
      const tax = this.taxes.find(t => t.id == this.taxesId);
      this.$emit("onSelectTax", tax);
    },
    async getTaxesTypes() {
      const { data } = await SriServices.getTaxesTypes();
      const { success, data: taxes } = data;
      if (success) {
        this.taxes = taxes;
      }
    }
  },
  props: {
    setTaxesId: {
      default: null
    }
  },
  mounted() {
    this.getTaxesTypes();
  }
};
</script>

<style lang="scss" scoped></style>
