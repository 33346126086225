import Api from "@/config/Api";

export default {
  getFormsPayments() {
    return Api().get("/sri/forms-payments", {});
  },
  getWithholdingTaxes() {
    return Api().get("/sri/withholding-taxes", {});
  },
  getDocumentTypes() {
    return Api().get("/sri/document-types", {});
  },
  getTaxesTypes() {
    return Api().get("/sri/taxes-types", {});
  }
};
